@import "/src/common.scss";

.data_amount_container {
  width: 100%;
  @include justi-between;
  gap: 24px;
  margin-bottom: 30px;
}

@media (max-width: 768px) {
  .data_amount_container {
    flex-wrap: wrap;
  }
}
