@import "/src/color.scss";
@import "/src/common.scss";

.section {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.container {
  display: flex;

  #menu_container {
    width: 270px;
    height: 100%;
    box-shadow: 9px 0px 10px 0px rgba(0, 0, 0, 0.1);
    background-color: $gray-50;
  }

  #outlet_container {
    box-sizing: border-box;
    overflow-y: scroll;
    width: calc(100vw - 270px);
    @include justi-center;
    padding: 56px 24px;
    background-color: $gray-200;
  }
}

@media (max-width: 1900px) {
  #menu_container {
    width: 0 !important;
  }

  #outlet_container {
    width: 100vw !important;
  }
}
