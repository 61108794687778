@import "/src/color.scss";
@import "/src/common.scss";

.report_button {
  @include justi-align-center;
  width: 100px;
  height: 31px;
  border-radius: 5px;
  background-color: $gray-200;
  color: $gray-600;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
}
