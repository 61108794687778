@import "~rsuite/styles/index.less";

.table_container {
  padding: 26px 25px 20px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;

  .rs-table {
    font-size: 16px;
    height: auto;
    color: #212121;

    &-row-header {
      overflow: hidden;
      border-bottom: 1px solid #e0e0e0;
      color: #212121;

      .rs-table-cell-content {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &-body-wheel-area {
      .rs-table-row {
        border-bottom: 1px solid #e0e0e0;
      }

      .rs-table-row:last-child {
        border-bottom: none;
      }
    }

    &-cell-group {
      .rs-table-cell {
        background-color: transparent;
        border-bottom: none;

        &-content {
          line-height: 52px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }
  }
}

.table_container_in_modal {
  padding: 26px 25px 20px;
  background-color: white;
  border-radius: 15px;
  overflow: hidden;

  .rs-table {
    font-size: 16px;
    height: auto;
    color: #212121;

    &-row-header {
      overflow: hidden;
      color: #212121;
      background-color: #eeeeee;
      border-radius: 5px;

      .rs-table-cell-content {
        font-size: 16px;
        font-weight: 700;
        line-height: 24px;
      }
    }

    &-body-wheel-area {
      .rs-table-row {
        border-bottom: 1px solid #e0e0e0;
      }

      .rs-table-row:last-child {
        border-bottom: none;
      }
    }

    &-cell-group {
      .rs-table-cell {
        background-color: transparent;
        border-bottom: none;

        &-content {
          line-height: 33px;
          font-size: 16px;
          padding: 0 10px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .rs-table {
    font-size: 8px;

    &-row {
      min-width: 320px !important;
    }

    &-row-header {
      min-width: 320px !important;
    }

    &-cell-group {
      .rs-table-cell {
        &-content {
          font-size: 12px;
          padding: 0;
        }
      }
    }
  }
}
