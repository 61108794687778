@import "/src/common.scss";
@import "/src/color.scss";

.search_container {
  @include justi-between-align-center;
  margin-top: 60px;

  p {
    color: $gray-900;
    font-size: 18px;
    font-weight: 700;
  }

  .search_box {
    position: relative;

    input {
      width: 297px;
      height: 34px;
      outline: none;
      border: none;
      border-radius: 5px;
      padding-left: 12px;
    }

    input:focus {
      border: 2px solid $union;
    }

    .search_icon {
      position: absolute;
      top: 50%;
      right: 7px;
      left: 275px;
      width: 20px;
      height: 20px;
      padding: 0;
      background-color: transparent;
      transform: translateY(-50%);
      cursor: pointer;
    }
  }
}
