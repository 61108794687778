@import "common.scss";
@import "color.scss";

.login_page {
  width: 100vw;
  height: 100vh;
  @include flex-column-center;
  background-color: $gray-100;

  .logo {
    margin-bottom: 45px;
  }

  form {
    @include flex-column;
    width: 452px;
    gap: 15px;
  }

  .login_btn {
    width: 452px;
    height: 44px;
    font-size: 16px;
    background-color: $union;
    border: none;
    color: white;
    border-radius: 8px;
    margin-top: 15px;
    margin-bottom: 32px;
    cursor: pointer;
  }

  span {
    font-size: 12px;
    color: $gray-500;
  }
}
