body {
  margin: 0;
  font-family: "AppleSDGothicNeo";
  font-weight: 400;
  font-style: normal;

  p {
    margin: 0;
  }
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url(./assets/font/AppleSDGothicNeoB.ttf);
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url(./assets/font/AppleSDGothicNeoM.ttf);
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url(./assets/font/AppleSDGothicNeoR.ttf);
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url(./assets/font/AppleSDGothicNeoL.ttf);
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "AppleSDGothicNeo";
  src: url(./assets/font/AppleSDGothicNeoT.ttf);
  font-weight: 100;
  font-style: normal;
}
