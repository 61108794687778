@import "/src/common.scss";

.data_graph_container {
  box-sizing: border-box;
  width: 100%;
  height: 384px;
  padding: 15px 25px;
  @include flex-column;
  gap: 15px;
  background-color: white;
  border-radius: 15px;

  .chart {
    width: 100%;
    height: 312px;
  }
}
