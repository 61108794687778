@import "/src/color.scss";
@import "/src/common.scss";

.dialog {
  position: relative;
  text-align: center;
  box-sizing: border-box;
  width: 800px;
  height: 577px;
  padding: 0 100px;
  border: none;
  border-radius: 14px;
  color: $gray-900;
  font-weight: 400;

  .close_icon {
    position: absolute;
    top: 21px;
    right: 26px;
    width: 31px;
    height: 32px;
    cursor: pointer;
  }

  h2 {
    margin-top: 77px;
    font-size: 18px;
    font-weight: 700;
  }

  .box_container {
    @include flex-column;
    margin-top: 40px;
    gap: 10px;

    .date_company_box {
      @include justi-between;

      div {
        @include align-center;
        gap: 6px;
      }

      span {
        color: $gray-600;
        font-size: 14px;
        font-weight: 700;
      }

      p {
        @include align-center;
        box-sizing: border-box;
        width: 240px;
        height: 34px;
        padding: 0 12px;
        border-radius: 5px;
        border: 1px solid $gray-400;
      }
    }

    .title_box {
      display: flex;
      justify-content: right;
      gap: 6px;
      align-items: center;

      p {
        @include align-center;
        box-sizing: border-box;
        width: 545px;
        height: 34px;
        padding: 0 12px;
        border-radius: 5px;
        border: 1px solid $gray-400;
      }
    }
  }

  .table_container {
    margin-bottom: 50px;

    .total_amount {
      text-align: left;
      margin-top: 45px;
      font-size: 16px;
      font-weight: 700;
      line-height: 1.5;
    }

    .table > div {
      padding: 0 !important;
    }

    .total {
      display: flex;
      border-top: 1.5px solid #bdbdbd;

      p {
        @include justi-align-center;
        width: 25%;
        height: 40px;
        text-align: center;
        font-size: 14px;
        line-height: 21px;
        font-weight: 900;
      }

      p:last-child {
        color: white;
        background-color: $union;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }
}
