@import "/src/color.scss";
@import "/src/common.scss";

.page_title {
  @include justi-between-align-center;

  h2 {
    color: $union;
    font-size: 18px;
  }

  div {
    display: flex;
    @include align-center;
    gap: 25px;
    cursor: pointer;
  }

  .menu {
    display: none;
  }
}

@media (max-width: 1900px) {
  .menu {
    display: block !important;
    cursor: pointer;
  }
}
