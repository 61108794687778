.amount_box {
  box-sizing: border-box;
  width: 292px;
  height: 100%;
  padding: 15px 25px;
  background-color: white;
  border-radius: 15px;

  p:first-child {
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: bold;
    line-height: 150%;
    color: #9e9e9e;
  }

  p:last-child {
    font-size: 22px;
    font-weight: bold;
    line-height: 140%;
    color: #424242;
  }
}

@media (max-width: 768px) {
  .amount_box {
    width: 100%;
  }
}

@media (max-width: 810px) {
  .amount_box > p:first-child {
    font-size: 14px;
  }
}
