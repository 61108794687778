@import "/src/color.scss";
@import "/src/common.scss";

.logo_container {
  @include justi-align-center;
  margin-top: 35px;
}

.line {
  margin-top: 33px;
}

.nav_container {
  width: 230px;
  height: 54px;
  margin-top: 33px;
  @include flex-column;
  gap: 8px;
  font-family: "AppleSDGothicNeo";
  font-size: 14px;
  border: none;
  color: black;
  margin-left: 17px;
  font-weight: 700;
}

.link {
  color: $gray-500;
  text-decoration: none;
  border-radius: 20px;
  @include align-center;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
  }

  &:hover {
    background-color: white;
    font-weight: bold;
    box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
  }

  .nav_icon {
    margin: 8px 25px 0 16px;
  }
}

.active {
  color: black;
  background-color: white;
  font-weight: bold;
  box-shadow: 0px 5px 7px 0px rgba(0, 0, 0, 0.05);
}
