@import "common.scss";
@import "color.scss";

.input_container {
  position: relative;
  @include align-center;

  .input_style {
    width: 452px;
    height: 49px;
    border-radius: 8px;
    padding-left: 21px;
    font-size: 16px;
    border: 1px solid $gray-400;
    outline: none;

    &::placeholder {
      color: $gray-600;
    }

    &:focus {
      border: 1px solid $union;
    }
  }

  .visible_icon {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
    cursor: pointer;
  }
}
