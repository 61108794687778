.rs-pagination-group {
  justify-content: center;
  margin-top: 60px;

  .rs-pagination {
    &-btn {
      width: 34px !important;
      height: 34px !important;
      color: #757575;
      background-color: #e0e0e0;

      &[title="Next"],
      &[title="Previous"] {
        background-color: transparent;
      }

      &.rs-pagination-btn-active,
      &.rs-pagination-btn-active:hover,
      &.rs-pagination-btn-active:focus {
        border: none;
        background-color: #22b6e9;
        box-shadow: none;
        color: white;
      }
    }

    &-md {
      .rs-pagination-btn {
        padding: 18px;
      }
    }

    .rs-ripple-pond {
      display: none;
    }
  }
}
