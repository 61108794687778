@import "common.scss";
@import "color.scss";

.container {
  @include align-center;
  gap: 5px;
  color: $gray-600;

  .checkbox {
    width: 16px;
    height: 16px;
    cursor: pointer;
    appearance: none;
    border: 1px solid $gray-400;
    border-radius: 2px;
    position: relative;

    &:checked {
      background-color: $union;
      border: none;

      &::after {
        content: "";
        width: 4px;
        height: 10px;
        border: solid white;
        border-width: 0 1.5px 1.5px 0;
        position: absolute;
        top: 40%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
      }
    }
  }
}
