@import "/src/color.scss";

.amount_box {
  box-sizing: border-box;
  width: 218px;
  height: 123px;
  border-radius: 15px;
  padding: 29px 30px;
  background: white;

  p {
    color: $gray-900;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
  }

  span {
    color: $gray-900;
    font-size: 28px;
    font-weight: 700;
    line-height: 1.4;
    margin-top: 5px;
  }
}
.amount_box:first-child {
  span {
    color: $union;
  }
}
